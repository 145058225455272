.asset-thumbnail {
    border: none;
    /* background-color: rgba(17, 17, 20, 0.4) !important; */
}

.asset-thumbnail.card-header {
    padding: 0 !important;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.asset-thumbnail .summoning-info .regular-summoning {
    position: absolute !important;
    left: 5%;
    top: 5%;
    padding: 5px 15px;
    background-color: #996633;
    border-radius: 1.625rem;
    background: rgb(2, 0, 36);
}

.asset-thumbnail .summoning-info .special-summoning {
    position: absolute !important;
    left: 5%;
    top: 20%;
    padding: 5px 15px;
    border-radius: 1.625rem;
    background: rgb(2, 0, 36);
}

.asset-thumbnail .regular-summoning .summoning-text {
    display: none;
}

.asset-thumbnail .regular-summoning:hover .summoning-text {
    display: inline-block !important;
    /* transition: all 0.3s ease-in-out; */
}

.asset-thumbnail .special-summoning .summoning-text {
    display: none;
}

.asset-thumbnail .special-summoning:hover .summoning-text {
    display: inline-block !important;
}

.asset-thumbnail .summoning-info .special-summoning:hover {
    transition: all 0.3s ease-in-out;
    background: rgb(84, 98, 255);
    background: linear-gradient(90deg, rgba(84, 98, 255, 1) 0%, rgba(173, 0, 180, 1) 28%, rgba(193, 17, 0, 1) 55%, rgba(193, 122, 0, 1) 72%, rgba(190, 188, 0, 0.85) 100%);
    /* color: black; */
    font-weight: bold;
    border: none;
}

.asset-thumbnail .summoning-info .regular-summoning:hover {
    transition: all 0.3s ease-in-out;
    color: black;
    background-color: #ffcc99;
}

.asset-thumbnail .nft-nonce {
    position: absolute;
    top: 5%;
    right: 5%;
    user-select: none;
}

.asset-thumbnail .nft-type {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 0.625rem;
    padding: 5px;
    font-size: medium;
}

.asset-thumbnail .nft-price-native {
    position: absolute;
    bottom: 0;
    font-weight: bolder;
    background-color: rgba(0, 0, 0, 0.6);
    width: 100%;
    font-size: large;
    z-index: 2;
}

.asset-thumbnail .nft-price-native .hammer-icon {
    color: #996633;
}

.asset-thumbnail .nft-price-native .chain-logo {
    max-width: 8%;
    margin-bottom: 2px;
    margin-left: 10px;
}

.asset-thumbnail .nft-price-usd {
    position: absolute;
    bottom: 0;
    font-weight: bolder;
    background-color: rgba(0, 0, 0, 0.6);
    width: 100%;
    font-size: large;
    opacity: 0;
    filter: alpha(opacity=0);
}

.asset-thumbnail .nft-price-usd .hammer-icon {
    color: #996633;
}

.asset-thumbnail .nft-price-usd .chain-logo {
    max-width: 8%;
    margin-bottom: 2px;
    margin-left: 10px;
}

.asset-thumbnail .nft-price-native:hover+.nft-price-usd {
    opacity: 1 !important;
    filter: alpha(opacity=1);
    transition: all 0.3s ease-in-out;
}

.asset-thumbnail .nft-price-native:hover {
    opacity: 0;
    filter: alpha(opacity=0);
    transition: all 0.1s ease-in-out;
}

.asset-thumbnail .nft-quantity {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 0.625rem;
    padding: 5px;
    margin-bottom: 10px;
    margin-right: 10px;
}

.asset-thumbnail .nft-quantity.with-nft-price {
    margin-bottom: 35px;
}