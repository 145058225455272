.pending-summoning-wrapper {
    position: relative;
}

.pending-summoning-wrapper .death-soul img {
    background-image: url("./death.png");
    background-size: 110% auto;
    margin: 5px;
}

.claim-message {
    position: absolute;
    display: none;
    cursor: pointer;
}

.pending-summoning-wrapper:hover .claim-message {
    display: block;
}

.pending-summoning-wrapper:hover img {
    filter: brightness(50%);
    cursor: pointer;
    border: 1px black solid;
}