.staked-item .selected {
    border: 1px gold solid;
    transition: all 0.3s ease-in-out;
}

.staked-item .selectable:hover {
    border: 2px gold solid;
    transition: all 0.3s ease-in-out;
    border-radius: .625rem;
    transform: scale(1.05);
}

.staked-item .stake-info {
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 0.625rem;
    padding: 5px;
    font-size: small;
    cursor: pointer;
}

.staked-item .qty-picker {
    /* position: absolute;
    z-index: 100;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%); */
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 0.625rem;
    padding: 5px;
    font-size: small;
    cursor: pointer;
}