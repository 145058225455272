body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

html {
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body>.main-content {
  flex-grow: 1;
  overflow: auto;
}

.rounded {
  border-radius: 0.625rem;
}

.navbar {
  height: 85px !important;
}

.btn {
  border-radius: 0.5rem !important;
  font-size: 1rem !important;
  font-weight: 600 !important;
  line-height: 1.5 !important;
  padding: 0.688rem 1.5rem !important;
}

.btn-lg {
  padding: 0.969rem 2rem !important;
}

.navbar {
  background-color: #2d2d37 !important;
}

.card, .modal-content {
  color: white;
  background-color: #2d2d37 !important;
  margin: 10px;
}

.card-header {
  align-items: center !important;
  background: 0 0 !important;
  display: flex !important;
  justify-content: space-between !important;
  padding: 1.5rem 1.875rem 1.25rem !important;
  position: relative !important;
}

.card {
  height: calc(100% - 1.4rem) !important;
}

.card-body {
  height: 100%;
  flex: 1 1 auto !important;
  padding: 1.25rem 1.875rem 1.25rem;
}

.bgl-primary {
  background-color: rgba(153, 102, 51, .1);
  border-color: rgba(153, 102, 51, .1);
}

.bgl-secondary {
  background-color: rgba(255, 204, 153, 0.5);
  border-color: rgba(255, 204, 153, 0.5);
}

.text-between {
  display: flex;
  justify-content: space-between;
}

.text-gray {
  color: gray;
}

small {
  font-size: .875rem !important;
  color: hsla(0, 0%, 100%, .6);
}

.text-left {
  text-align: left !important;
}

.with-scroll {
  overflow-y: scroll;
}

tr {
  border-color: hsla(0, 0%, 100%, .1) !important;
}

.table-responsive {
  padding: 20px;
}

.alert.alert-outline-warning {
  background: transparent;
  color: #ffab2d;
  border-color: #ffab2d;
}

.alert-rounded {
  border-radius: 3.125rem !important;
}

a {
  color: white !important;
  text-decoration: none !important;
}

.btn-block {
  display: block;
  width: 100%;
}

.input-rounded {
  border-radius: 6.25rem !important;
}

.form-control {
  background-color: #1e1e25 !important;
  border-color: hsla(0, 0%, 100%, .1) !important;
  color: #fff !important;
}

.overlay-box {
  position: relative;
  z-index: 1;
}

.overlay-box:after {
  content: '';
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  opacity: 0.85;
  background: #996633;
  z-index: -1;
}

.card-footer {
  border-color: rgba(255, 255, 255, 0.1);
  padding: 1.25rem 1.875rem 1.25rem;
}

.rounded {
  border-radius: 0.625rem !important;
}

.img-responsive {
  width: auto;
  max-width: 100%;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  visibility: hidden;
}

/* Track */
::-webkit-scrollbar-track:hover {
  visibility: visible;
  border-color: rgba(255, 255, 255, 0.3);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;

}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.no-x-scroll {
  overflow-x: hidden !important;
}

.bg-black {
  background-color: black !important;
}

.selectable {
  position: relative;
}

.selectable:hover {
  border: 3px rgba(255, 204, 153, 1) solid;
}

.selected {
  border: 3px rgba(255, 204, 153, 0.4) solid;
}

.selected:hover {
  border: 2px rgba(255, 204, 153, 1) solid;
}

.bg-transparent {
  background-color: transparent !important;
}